import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { ShepherdService } from 'angular-shepherd';
import { AuthService } from 'src/app/auth.service';
import { ToggleThemeService } from 'src/app/shared/service/toggle-theme.service';
import { CommonService } from '../../../shared/service/common.service';
import { BlurHelperService } from '../../core/helpers/blur-helper.service';
import { LocalStorageService } from '../../core/services/localstorage.service';
import { RolesService } from './../../../shared/service/roles.service';

/* eslint-disable */

@Component({
  selector: 'nio-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  @Output() clickHamburger: EventEmitter<boolean> = new EventEmitter();
  isCollapsed = false;
  isLoggedIn = false;
  status = false;
  accounts = [];
  isAccountsLoaded = false;
  selectedAccount;
  isLoading = true;
  isAdmin = false;
  demoModeControl = new FormControl();
  $getUserAccounts: any;
  roles;
  claims;
  isCustomerAdmin = false;
  isNetsyncioUser = false;
  isPresalesEngineer = false;
  isAccountManager = false;
  landing = false;
  @Input() showNavigation = false;
  constructor(
    public router: Router,
    public commonService: CommonService,
    public blurHelper: BlurHelperService,
    public themeService: ToggleThemeService,
    public dialog: MatDialog,
    private shepherdService: ShepherdService,
    private roleService: RolesService,
    private authService: AuthService,
    private localStorageService: LocalStorageService
  ) {
    router.events.subscribe((val: RouterEvent) => {
      if (val instanceof NavigationEnd) {
        // Check if current page is not landing page
        this.landing = val.url === '/';
        // tslint:disable-next-line: no-conditional-assignment
        if ((this.landing = val.url === '/')) {
          this.showNavigation = false;
        } else {
          this.showNavigation = true;
        }
      }
    });
  }

  ngOnInit(): void {
    this.isLoggedIn = this.router.url === '/';

    this.isAdmin = this.roleService.checkIfUserIsAdmin();
    this.isNetsyncioUser = this.roleService.checkIfUserIsNormalUser();
    this.isCustomerAdmin = this.roleService.checkIfUserIsCustomerAdmin();
    this.isPresalesEngineer = this.roleService.checkIfUserIsPresalesEngineer();
    this.isAccountManager = this.roleService.checkIfUserIsAccountManger();

    this.claims = this.authService.getClaims();

    this.authService?.events().subscribe((res) => {
      if (
        res?.type == 'token_received' ||
        res?.type == 'discovery_document_loaded'
      ) {
        setTimeout(() => {
          this.isAdmin = this.roleService.checkIfUserIsAdmin();
          this.isNetsyncioUser = this.roleService.checkIfUserIsNormalUser();
          this.isCustomerAdmin = this.roleService.checkIfUserIsCustomerAdmin();
          this.isPresalesEngineer =
            this.roleService.checkIfUserIsPresalesEngineer();
          this.isAccountManager = this.roleService.checkIfUserIsAccountManger();

          this.claims = this.authService.getClaims();

          this.isLoggedIn = this.isUserLoggedIn();
          // this.commonService.getAccounts().subscribe();
        }, 500);
      }
    });

    this.isLoggedIn = this.router.url === '/';
    this.commonService.selectedAccountChanged.subscribe((account) => {
      this.selectedAccount = account;
    });
    this.demoModeControl.setValue(this.blurHelper.enableBlur);
  }

  toggle() {
    this.isCollapsed = !this.isCollapsed;
    this.clickHamburger.emit(this.isCollapsed);
    this.commonService.clickHamburger.next(this.isCollapsed);
  }

  async onLogin(strategy: string = 'auth0') {
    if (
      !this.authService.isAzureIDP() &&
      new RegExp('azure', 'ig').test(strategy)
    ) {
      await this.authService.loginWithAzureAD();
    } else if (
      !this.authService.isAuth0IDP() &&
      new RegExp('auth0', 'ig').test(strategy)
    ) {
      await this.authService.loginWithAuth0();
    }
  }

  getImage() {
    return this.authService.isAzureIDP()
      ? '/assets/icons/azure-logo.png'
      : '/assets/icons/auth0-black-logo.png';
  }

  onLogoutClick() {
    this.authService.logout();
  }

  isUserLoggedIn() {
    this.isAdmin = this.roleService.checkIfUserIsAdmin();

    const isLoggedIn =
      this.router.url !== '/' && this.router.url.indexOf('redirect') === -1;
    if (
      isLoggedIn &&
      this.accounts.length === 0 &&
      !this.isAccountsLoaded &&
      !this.$getUserAccounts
    ) {
      if (
        this.isAdmin ||
        this.isCustomerAdmin ||
        this.isPresalesEngineer ||
        this.isAccountManager
      ) {
        // this.$getUserAccounts = this.commonService?.getAccounts()?.subscribe(
        //   (accounts?) => {
        //     this.isLoading = false;
        //     this.isAccountsLoaded = true;
        //     this.accounts = accounts;
        //     const isClosedTour = localStorage?.getItem('tourEnded');
        //     //make check here.
        //     if (
        //       claims !== null &&
        //       this.accounts?.length === 0 &&
        //       !isClosedTour
        //     ) {
        //       this.shepherdService.defaultStepOptions = defaultStepOptions;
        //       this.shepherdService.modal = true;
        //       this.shepherdService.confirmCancel = false;
        //       this.shepherdService.addSteps(defaultSteps);
        //       this.shepherdService.start();
        //     } else {
        //       this.endTour();
        //     }
        //   },
        //   () => {
        //     this.isLoading = false;
        //     this.commonService.selectedAccountChanged.next({
        //       Id: 'null',
        //       Name: 'null',
        //       Description: 'null',
        //     });
        //   }
        // );
      }

      if (this.isNetsyncioUser) {
        //new impelementation
        this.isLoading = true;

        // if (claims !== null && this.authService.isAuthenticated()) {
        //   this.$getUserAccounts = this.commonService.getAccounts()?.subscribe(
        //     (accounts: any) => {
        //       this.isLoading = false;
        //       this.isAccountsLoaded = true;

        //       this.accounts = accounts;

        //       const isClosedTour = localStorage.getItem('tourEnded');

        //       //make check here.
        //       if (
        //         claims !== null &&
        //         this.accounts?.length === 0 &&
        //         !isClosedTour
        //       ) {
        //         this.shepherdService.defaultStepOptions = defaultStepOptions;
        //         this.shepherdService.modal = true;
        //         this.shepherdService.confirmCancel = false;
        //         this.shepherdService.addSteps(defaultSteps);
        //         this.shepherdService.start();
        //       } else {
        //         this.endTour();
        //       }
        //     },
        //     () => {
        //       this.isLoading = false;
        //       this.commonService.selectedAccountChanged.next({
        //         Id: 'null',
        //         Name: 'null',
        //         Description: 'null',
        //       });
        //     }
        //   );
        // }
      }
    }
    return isLoggedIn;
  }

  canAccessAccounts() {
    return (
      this.router.url !== '/' &&
      this.router.url.indexOf('redirect') === -1 &&
      this.router.url.indexOf('unauthorized') === -1
    );
  }

  getClaims() {
    return this.authService.getClaims();
  }

  getRoles() {
    const claims = (this.getClaims() as any) || {};
    if (
      localStorage.getItem(this.themeService.THEME_KEY) ===
      this.themeService.DARK_THEME_VALUE
    ) {
      this.themeService.setDarkTheme();
    } else {
      this.themeService.setLightTheme();
    }

    if (typeof claims.role === 'string') {
      this.roles = claims?.role;

      this.isAdmin = Boolean(this.roles.includes('admin'));
      this.isCustomerAdmin = Boolean(
        this.roles.includes('netsyncio customer admin')
      );
      this.isNetsyncioUser = Boolean(this.roles.includes('netsyncio user'));
    } else {
      this.roles = claims?.role?.filter(
        (item) =>
          item.toLowerCase().indexOf('netsyncio') > -1 ||
          item.toLowerCase().indexOf('manager') > -1
      );
      this.isAdmin = Boolean(
        this.roles?.find((role: string) => role.toLowerCase().includes('admin'))
      );

      this.isPresalesEngineer = Boolean(
        claims.role?.find((x) => x.toLowerCase() === 'pre sales engineers')
      );

      this.isAccountManager = Boolean(
        claims.role?.find((x) => x.toLowerCase() === 'netsync account manager')
      );
    }

    return claims.role;
  }

  accountChange(event, account) {
    event.stopPropagation();

    this.commonService.selectedAccountChanged.emit(account);
  }

  demoModeHandler(event) {
    this.blurHelper.setBlur(event.checked);
  }

  toggleDarkMode(e) {
    // console.log('@DarkMode >>> ', e.checked);
    let activeMode = localStorage.getItem(this.themeService.THEME_KEY);
    if (e.checked) {
      this.themeService.setDarkTheme();
      activeMode = 'Dark Mode';
    } else {
      this.themeService.setLightTheme();
      activeMode = 'Light Mode';
    }
    // this.themeService.toggleTheme(false);
    const claims = this.localStorageService.get('id_token_claims_obj');
    // console.log('@claims >> header >> ', claims);
    if (claims && this.authService.isAuth0IDP()) {
      const requestData = {
        first_name: claims?.firstName,
        last_name: claims?.lastName,
        phone: claims?.phone,
        default_page: claims?.defaultPage,
        default_mode: activeMode,
        default_display_account: claims?.defaultDisplayAccount,
        picture: claims?.picture,
      };
      this.commonService
        .updateCustomerProfileInfo(requestData)
        .subscribe((data) => {
          let claims = this.localStorageService.get('id_token_claims_obj');
          claims.default_mode = activeMode;
          this.localStorageService.set('id_token_claims_obj', {
            ...claims,
          });
          // console.log('@activeMode >> ', activeMode);
          // console.log('@this.localStorageService.set(id_token_claims_obj >> ', this.localStorageService.get('id_token_claims_obj'));
        }),
        () => {
          this.commonService.errorMessage(
            'Something went wrong, Please try again later.'
          );
        };
    }
  }

  startTour() {
    this.shepherdService.start();
  }

  endTour() {
    localStorage.setItem('tourEnded', 'true');
    this.shepherdService?.complete();
  }
}
