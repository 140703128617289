import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { carouselOptions } from '../../shared/app.constant';
/* eslint-disable */

@Component({
  selector: 'nio-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LandingComponent implements OnInit, OnDestroy {
  bodyEL = document.getElementsByTagName('body')[0];
  carouselOptions = carouselOptions;

  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit(): void {
    this.bodyEL.classList.add('no-sidenav');
    if (this.authService.isAuthenticated()) {
      this.router.navigate(['/operate']);
    }
  }

  scroll(id) {
    const element = document.getElementById(id);
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  ngOnDestroy(): void {
    this.bodyEL.classList.remove('no-sidenav');
  }
}
