<ngx-loading-bar [height]="'4px'" color="var(--progress)" [includeSpinner]="false"></ngx-loading-bar>
<nio-splash-screen *ngIf="showSplash"></nio-splash-screen>
<nio-header></nio-header>
<mat-sidenav-container class="main-content mat-drawer-transition"
  [ngClass]="{ pagetoggle: commonService.clickHamburger.value }">
  <mat-sidenav mode="side" opened #sidenav *ngIf="isAuthorised()"
    [style.width.vw]="commonService.clickHamburger.value ? 3.5 : 13">
    <nio-sidebar></nio-sidebar>
  </mat-sidenav>
  <mat-sidenav-content #container [class.no-padding]="noPadding" [style.marginLeft.vw]="
      isAuthorised() && commonService.clickHamburger.value
        ? 3.5
        : isAuthorised()
        ? 13
        : 0
    " [style.marginRight.vw]="
      isAuthorised() && commonService.clickHamburgerHelp.value
        ? 2
        : isAuthorised()
        ? 18
        : 0
    " (scroll)="handleScroll(container)">
    <main>
      <router-outlet (activate)="onActivate(container)"></router-outlet>
    </main>
    <footer *ngIf="!landing">
      <div>
        <small>
          © {{currentYear}} Netsync Network Solutions | Arapawa
          {{ currentApplicationVersion }}
        </small>
        <small> Made with <span id="heart">❤</span> in Netsync </small>


      </div>
    </footer>
    <button [style.opacity]="showScrollButton ? '1' : '0'" class="mine" mat-icon-button disableRipple id="scrolToTop"
      (click)="onActivate(container)">
      <!-- <mat-icon>arrow_upward</mat-icon> -->
      <!-- <span id="scroll"></span> -->
      <img src="assets/icons/chevron.svg" />
    </button>
  </mat-sidenav-content>
  <!-- <mat-sidenav id="help-feature" mode="side" opened #sidenav *ngIf="isAuthorised()"
    [style.width.vw]="commonService.clickHamburgerHelp.value ? 2 : 18">
    <nio-help-sidebar></nio-help-sidebar>
  </mat-sidenav> -->
</mat-sidenav-container>