import {
  animate, state,
  style,
  transition, trigger
} from '@angular/animations';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { RolesService } from 'src/app/shared/service/roles.service';
import { FeedbackComponent } from './../../../shared/components/feedback/feedback.component';

/* eslint-disable */

@Component({
  selector: 'nio-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('animation', [
      state('void', style({ height: '0px', opacity: 0 })),
      state('*', style({ height: '*', opacity: 1 })),
      transition(':enter', animate('200ms ease-out')),
      transition(':leave', animate('200ms ease-in')),
    ]),
  ],
})
export class SidebarComponent implements OnInit {
  isCustomerAdmin = this.rolesService.checkIfUserIsCustomerAdmin();
  isNetsyncioUser = this.rolesService.checkIfUserIsNormalUser();
  isPresalesEngineer = this.rolesService.checkIfUserIsPresalesEngineer();
  isAccountManager = this.rolesService.checkIfUserIsAccountManger();

  isAdmin = this.rolesService.checkIfUserIsAdmin();
  isInstructor = this.rolesService.checkIfUserIsInstructor();
  items = [
    {
      name: 'Classes',
      image: 'icon-navi navi-measure',
      link: '/classes',
      adminOnlyAccess: false,
      isCustomerAdmin: this.isCustomerAdmin,
      canAccess: false,
      instructorCanAccess: true,
      subLinks: [],
    },
    {
      name: 'Admin',
      image: 'icon-navi navi-deploy',
      link: '/admin/classes/actions',
      adminOnlyAccess: true,
      isCustomerAdmin: this.isCustomerAdmin,
      canAccess: false,
      instructorCanAccess: false,
    },
    // {
    //   name: 'Migration',
    //   image: 'icon-navi navi-migration',
    //   link: '/migration',
    //   canAccess: false,
    //   isCustomerAdmin: !this.isCustomerAdmin,
    //   isCustomerAdminCanAccess: false,
    //   isPresalesEngineer: false,
    //   isAccountManager:false,

    //   subLinks: [
    //     {
    //       name: 'Import Data',
    //       link: '/migration/import-data',
    //       canAccess: false,
    //       isCustomerAdminCanAccess: false,
    //       isPresalesEngineer: false,
    //       isAccountManager:false,

    //     },
    //     {
    //       name: 'Current Inventory',
    //       link: '/migration/current-inventory',
    //       canAccess: false,
    //       isCustomerAdminCanAccess: false,
    //       isPresalesEngineer: false,
    //       isAccountManager:false,

    //       dataPages: [],
    //     },
    //   ],
    //   adminOnlyAccess: false,
    // },
    // {
    //   name: 'Operate',
    //   image: 'icon-navi navi-operate',
    //   link: '/operate',
    //   canAccess: false,
    //   // isCustomerAdmin: this.isCustomerAdmin,
    //   isCustomerAdminCanAccess: true,
    //   isPresalesEngineer: true,
    //   isAccountManager:true,

    //   subLinks: [
    //     {
    //       name: 'Trusted Advisor',
    //       link: '/operate/trusted-advisor',
    //       canAccess: false,
    //       isCustomerAdmin: this.isCustomerAdmin,
    //       isCustomerAdminCanAccess: true,
    //       isPresalesEngineer: true,
    //       isAccountManager:true,
    //     },
    //     {
    //       name: 'CloudTrail',
    //       link: '/operate/cloudtrail',
    //       canAccess: false,
    //       // isCustomerAdmin: !this.isCustomerAdmin,
    //       isCustomerAdminCanAccess: false,
    //       isPresalesEngineer: false,
    //       isAccountManager:false,
    //     },
    //     {
    //       name: 'Accounts',
    //       link: '/operate/account-list',
    //       canAccess: false,
    //       isCustomerAdmin: this.isCustomerAdmin,
    //       isCustomerAdminCanAccess: true,
    //       isPresalesEngineer: true,
    //       isAccountManager:true,
    //     },
    //     {
    //       name: 'AppStream 2.0',
    //       link: '/operate/appstream-2.0',
    //       canAccess: false,
    //       isCustomerAdmin: this.isCustomerAdmin,
    //       isCustomerAdminCanAccess: true,
    //       isPresalesEngineer: true,
    //       isAccountManager:true,
    //     },
    //     {
    //       name: ' AWS Budget',
    //       link: '/operate/budget/list',
    //       canAccess: false,
    //       isCustomerAdmin: this.isCustomerAdmin,
    //       isCustomerAdminCanAccess: true,
    //       isPresalesEngineer: true,
    //       isAccountManager:true,
    //     },
    //     {
    //       name: 'Savings Plans',
    //       link: '/operate/savings-plans/list',
    //       canAccess: false,
    //       // isCustomerAdmin: !this.isCustomerAdmin,
    //       isCustomerAdminCanAccess: false,
    //       isPresalesEngineer: false,
    //       isAccountManager:false,
    //     },
    //     {
    //       name: 'Cloud Mapper',
    //       link: '/operate/cloud-mapper/accounts/list',
    //       canAccess: false,
    //       isCustomerAdminCanAccess: false,
    //       isPresalesEngineer: false,
    //       isAccountManager:false,

    //       // isCustomerAdmin: !this.isCustomerAdmin,
    //     },

    //     {
    //       name: 'Access Control List',
    //       link: 'operate/control-access/list',
    //       adminOnlyAccess: true,
    //       canAccess: false,
    //       isCustomerAdmin: this.isCustomerAdmin,
    //       isCustomerAdminCanAccess: true,
    //       isPresalesEngineer: true,
    //       isAccountManager:true,

    //       dataPages: [
    //         {
    //           name: 'Templates',
    //           link: 'operate/control-access/list',
    //           isCustomerAdminCanAccess: true,

    //           canAccess: false,
    //           adminOnlyAccess: true,
    //           isCustomerAdmin: this.isCustomerAdmin,
    //           isPresalesEngineer: true,
    //           isAccountManager:true,
    //         },
    //         {
    //           name: 'Users',
    //           link: 'operate/control-access/users',
    //           canAccess: false,
    //           adminOnlyAccess: true,
    //           isCustomerAdminCanAccess: true,
    //           isAccountManager:true,

    //           isCustomerAdmin: this.isCustomerAdmin,
    //           isPresalesEngineer: true,
    //         },
    //       ],
    //     },
    //   ],
    //   adminOnlyAccess: true,
    //   isCustomerAdmin: this.isCustomerAdmin,
    // },
    // {
    //   name: 'Train',
    //   image: 'icon-navi navi-train',
    //   isCustomerAdminCanAccess: true,
    //   isPresalesEngineer: true,
    //   isAccountManager:true,

    //   link: '/train',
    //   subLinks: [
    //     {
    //       name: 'Courses',
    //       link: '/train/courses/all',
    //       canAccess: false,
    //       isCustomerAdminCanAccess: true,
    //       isPresalesEngineer: true,
    //       isAccountManager:true,
    //     },
    //     // { name: 'WishList', link: '/train/wishlist' },
    //   ],
    //   adminOnlyAccess: false,
    //   canAccess: false,
    //   isCustomerAdmin: this.isCustomerAdmin,
    // },
    // {
    //   name: 'Netsync Tools',
    //   image: 'icon-navi navi-pricing',
    //   link: '/tools',
    //   isCustomerAdminCanAccess: false,
    //   isPresalesEngineer: true,
    //   isAccountManager:true,
    //   canAccess: false,
    //   isCustomerAdmin: !this.isCustomerAdmin,

    //   subLinks: [
    //     {
    //       name: 'Accounting',
    //       link: '/tools/accounting',
    //       canAccess: false,
    //       isCustomerAdminCanAccess: false,
    //       isPresalesEngineer: false,
    //       isAccountManager:false,
    //     },
    //     {
    //       name: 'Calculators',
    //       link: '/tools/calculators',
    //       canAccess: false,
    //       isCustomerAdminCanAccess: false,
    //       isPresalesEngineer: true,
    //       isAccountManager:true,
    //     },
    //     {
    //       name: 'Customer Accounts',
    //       link: '/tools/customer-accounts',
    //       canAccess: false,
    //       isPresalesEngineer: false,
    //       isAccountManager:false
    //     },
    //     {
    //       name: 'Marketing',
    //       link: '/tools/marketing',
    //       canAccess: false,
    //       isCustomerAdminCanAccess: false,
    //       isPresalesEngineer: false,
    //       isAccountManager:false,
    //     },
    //   ],
    //   adminOnlyAccess: true,
    // },
    // {
    //   name: 'Support',
    //   image: 'icon-navi navi-support',
    //   link: '/support',
    //   canAccess: false,
    //   isCustomerAdmin: this.isCustomerAdmin,
    //   isCustomerAdminCanAccess: true,
    //   isPresalesEngineer: true,
    //   isAccountManager:true,
    //   subLinks: [
    //     {
    //       name: 'Support Cases',
    //       link: '/support/support-cases',
    //       canAccess: false,
    //       isCustomerAdmin: this.isCustomerAdmin,
    //       isCustomerAdminCanAccess: true,
    //       isPresalesEngineer: true,
    //       isAccountManager:true,
    //     },
    //     //  {name: 'Cases History', link: '/support/history'}
    //   ],
    // },
  ];
  isCollapsed = false;

  constructor(
    public router: Router,
    private dialog: MatDialog,
    public commonService: CommonService,
    private rolesService: RolesService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.checkUserRole();
    this.assignRoleToMenu();
  }

  isValidLogin() {
    return (
      this.router.url !== '/' &&
      this.router.url.indexOf('redirect') === -1 &&
      this.router.url.indexOf('unauthorized') === -1
    );
  }

  giveFeedback() {
    const dialogRef = this.dialog.open(FeedbackComponent, {
      maxWidth: '400px',
      autoFocus: false,
    });

    return dialogRef.afterClosed();
  }

  toggle() {
    this.isCollapsed = !this.isCollapsed;
    this.commonService.clickHamburger.next(this.isCollapsed);
  }

  checkUserRole() {
    this.isAdmin = this.rolesService.checkIfUserIsAdmin();
    this.isCustomerAdmin = this.rolesService.checkIfUserIsCustomerAdmin();
    this.isNetsyncioUser = this.rolesService.checkIfUserIsNormalUser();

    // this.items.find((el) => el.link === '/tools').isCustomerAdmin =
    //   this.isCustomerAdmin;
    // this.items.find((el) => el.link === '/migration').isCustomerAdmin =
    //   this.isCustomerAdmin;
  }

  assignRoleToMenu() {
    const claims: any = this.authService.getClaims();

    if (this.isNetsyncioUser) {
      if (claims !== null && this.authService.isAuthenticated()) {
        this.commonService.accessListDataSubject?.subscribe((data: any) => {
          for (let item of data) {
            //check first level (module)
            for (let module of this?.items) {
              if (('/' + item)?.startsWith(module?.link)) {
                module.canAccess = true;
              }
              if (module?.subLinks) {
                for (let subLink of module?.subLinks) {
                  if (subLink.link == '/' + item) {
                    subLink.canAccess = true;
                    module.canAccess = true;
                  }
                  if (subLink['dataPages']) {
                    for (let dataPage of subLink['dataPages']) {
                      if (dataPage?.link == '/' + item) {
                        dataPage.canAccess = true;
                        subLink.canAccess = true;
                        module.canAccess = true;
                      }
                    }
                  }
                }
              }
            }
          }
        });
      }
    }
  }
}
