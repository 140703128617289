import { Injectable } from '@angular/core';
/* eslint-disable */

@Injectable({
  providedIn: 'root',
})
export class ToggleThemeService {
  readonly THEME_KEY = 'THEME';
  readonly DARK_THEME_VALUE = 'DARK';
  readonly LIGHT_THEME_VALUE = 'LIGHT';
  readonly DARK_THEME_CLASSNAME = 'dark-theme';
  readonly LIGHT_THEME_CLASSNAME = 'light-theme';

  constructor() {}

  get isDarkThemeSelected(): boolean {
    return localStorage.getItem(this.THEME_KEY) === this.DARK_THEME_VALUE;
  }

  public setThemeOnStart() {
    if (this.isDarkThemeSelected) {
      this.setDarkTheme();
    } else {
      this.setLightTheme();
    }

    document.body.classList.add('animate-theme-transition');
  }

  public toggleTheme() {
    // this.isDarkThemeSelected = !this.isDarkThemeSelected;
    if (this.isDarkThemeSelected) {
      this.setDarkTheme();
    } else {
      this.setLightTheme();
    }
  }

  public setDarkTheme() {
    localStorage.setItem(this.THEME_KEY, this.DARK_THEME_VALUE);
    document.body.classList.add(this.DARK_THEME_CLASSNAME);
    document.body.classList.remove(this.LIGHT_THEME_CLASSNAME);
  }

  public setLightTheme() {
    localStorage.setItem(this.THEME_KEY, this.LIGHT_THEME_VALUE);
    document.body.classList.add(this.LIGHT_THEME_CLASSNAME);
    document.body.classList.remove(this.DARK_THEME_CLASSNAME);
  }
}
