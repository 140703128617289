import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  get<T>(
    url: string,
    params:
      | HttpParams
      | {
          [param: string]: string | string[];
        } = null
  ) {
    const endPoint = `${url}`;
    return this.http.get<T>(endPoint, {
      params,
    });
  }

  post<T>(url, data) {
    const endPoint = `${url}`;
    return this.http.post<T>(endPoint, data);
  }

  postForTextResp(url, data) {
    const endPoint = `${url}`;
    return this.http.post(endPoint, data, { responseType: 'text' });
  }

  postByParam<T>(params, url) {
    const endPoint = `${url}`;

    return this.http.post<T>(
      endPoint,
      {},
      {
        params: new HttpParams({
          fromString: params.toString(),
        }),
      }
    );
  }

  postByParamBody<T>(params, body, url) {
    const endPoint = `${url}`;

    return this.http.post<T>(endPoint, body, {
      params: new HttpParams({
        fromString: params.toString(),
      }),
    });
  }

  postWithoutPreload<T>(url, options?) {
    const endPoint = `${url}`;
    return this.http.post<T>(endPoint, options);
  }

  public formDataToString(formData) {
    let httpParams = new HttpParams();
    const object = {};
    if (formData) {
      formData.forEach((value, key) => {
        object[key] = value;
      });
      for (const key in object) {
        if (object.hasOwnProperty(key)) {
          httpParams = httpParams.append(key, object[key]);
        }
      }
      return httpParams.toString();
    } else {
      return '';
    }
  }


// For RV Tools import ....
  putWithoutAuth<T>(url, data) {
    const endPoint = `${url}`;
    return this.http.put<T>(endPoint, data, {
      headers: new HttpHeaders().set('allow-auth', 'no'),
    });
    // return this.http.put<T>(endPoint, data);
  }

  //Normal PUT Method ...
  put<T>(url, data) {
    const endPoint = `${url}`;
    return this.http.put<T>(endPoint, data);
  }


  patch<T>(url, data) {
    const endPoint = `${url}`;
    return this.http.patch<T>(endPoint, data);
  }

  delete<T>(url,data?) {
    const endPoint = `${url}`;
    return this.http.delete<T>(endPoint, {
       params: data
    });
  }
}
