<div class="row">
    <div class="col-md-12 restricted__access">
        <h2 *ngIf="!errorMsg">ERR 401: Unauthorized</h2>
        <p *ngIf="!errorMsg">You are not authorized to view this page.</p>
        <p *ngIf="!errorMsg">Please contact your company's administrator if you need additional permissions.</p>
        <h2 *ngIf="errorMsg"> Email Verification</h2>
        <p *ngIf="errorMsg"> Please Verify Your Email Address!</p>
        <p *ngIf="errorMsg">{{errorMsg}}</p>
    </div>
</div>
