// This api will come in the next version

import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export enum IDPType {
  auth0 = 'auth0',
  azure = 'azure',
}

export const authConfig = (
  IDP: IDPType = IDPType[environment?.identityProviders?.defaultConfig] ||
    IDPType.auth0
): AuthConfig => {
  const IDPconfig: AuthConfig = { ...environment?.identityProviders[IDP] };
  return IDPconfig;
};
