import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortArr'
})
export class SortArrPipe implements PipeTransform {

  transform(value: Array<any>, sortType: string, isStudents?: boolean): Array<any> {
    const arr = value;
    if(sortType === 'asc') {
      if(isStudents) {
        return arr?.sort((a,b) => (a.student_last_name > b.student_last_name) ? 1 : ((b.student_last_name > a.student_last_name) ? -1 : 0));
      }
      return arr?.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    } else if(sortType === 'desc') {
      if(isStudents) {
        return arr?.sort((a,b) => (a.student_last_name < b.student_last_name) ? 1 : ((b.student_last_name < a.student_last_name) ? -1 : 0));
      }
      return arr?.sort((a,b) => (a.name < b.name) ? 1 : ((b.name < a.name) ? -1 : 0));
    } else {
      return arr;
    }

  }

}
