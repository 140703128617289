import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'nio-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  title: string;
  message: string;
  public fName: string;
  public fIndex: any;
  // nameControl: FormControl = new FormControl('', [Validators.required]);

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data?: ConfirmDialogModel
  ) {
    // Update view with given values
    this.title = data?.title;
    this.message = data?.message;
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close({
      confirm: true,
      // name: this.nameControl.value
    });
    this.dialogRef.close(this.fIndex);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close({ confirm: false });
    this.dialogRef.close();
  }
}

export class ConfirmDialogModel {
  constructor(public title: string, public message: string) {}
}
