// Angular
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
// RxJS
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth.service';
import { environment } from 'src/environments/environment';

/* eslint-disable */

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  // intercept request and add token
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const apiUrl =
      request.url.indexOf('http') > -1 ? '' : environment.apiBaseUrl + '/';
    let apiRequest = null;
    if (this.authService.getAccessToken()) {
      if (request?.headers.get('allow-auth') == 'no') {
        apiRequest = request.clone({
          url: `${apiUrl + request.url}`,
        });
      } else {
        apiRequest = request.clone({
          url: `${apiUrl + request.url}`,
          setHeaders: {
            Authorization: `Bearer ${this.authService.getAccessToken()},UTKN ${this.authService.getIDToken()}`,
          },
        });
      }
    } else {
      apiRequest = request.clone({
        url: `${apiUrl + request.url}`,
      });
    }
    return next.handle(apiRequest).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse) {
            // console.log('all looks good');
            // http response status code
            // console.log(event.status);
          }
        },
        (error) => {
          // http response status code
          // console.log('----response----');
          // console.error('status code:');
          // eslint-disable-next-line no-debugger
          console.error(error.status);
          console.error(error.message);
          // this.handleAuthError(error)
          return next.handle(request).pipe(
            catchError((error) => {
              if (error.status === 401) {
                // Handle 401 Unauthorized error
                this.authService.logout();
              }
              return throwError(error);
            })
          );
          // console.log('--- end of response---');
        }
      )
    );
  }

  //    private handleAuthError(err: HttpErrorResponse): Observable<any> {
  //     //handle your auth error or rethrow
  //     if (err.status === 401 || err.status === 403) {
  //         //navigate /delete cookies or whatever
  //         this.router.navigateByUrl(`/`);
  //         // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
  //         return of(err.message); // or EMPTY may be appropriate here
  //     }
  //     return throwError(err);
  // }
}
