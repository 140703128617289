import { Injectable } from '@angular/core';

import {
  BehaviorSubject,
  combineLatest,
  Observable,
  ReplaySubject,
} from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/auth.service';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  private isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  private isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  /** * Publishes `true` if and only if (a) all the asynchronous initial
   *  * login calls have completed or errorred, and (b) the user ended up
   * being authenticated.
   * In essence, it combines:
   *
   * - the latest known state of whether the user is authorized
   * - whether the ajax calls for initial log in have all been done
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$,
  ]).pipe(map((values: any) => values.every((b: any) => b)));

  constructor(private authService: AuthService) {}

  checkIfUserIsAdmin() {
    const claims: any = this.authService.getClaims();
    let roles = [];

    if (typeof claims?.role === 'string') {
      roles = [claims?.role];
    } else {
      roles = claims?.role;
    }

    return roles?.findIndex((item) => item === 'ILE-Admin') > -1;
  }
  checkIfUserIsCustomerAdmin() {
    const claims: any = this.authService.getClaims();
    let roles = [];

    if (typeof claims?.role === 'string') {
      roles = [claims?.role];
    } else {
      roles = claims?.role;
    }

    return (
      roles?.findIndex(
        (item) => item.toLowerCase() === 'netsyncio customer admin'
      ) > -1
    );
  }

  checkIfUserIsNormalUser() {
    const claims: any = this.authService.getClaims();

    let roles = [];

    if (typeof claims?.role === 'string') {
      roles = [claims?.role];
    } else {
      roles = claims?.role;
    }

    return (
      roles?.findIndex((item) => item.toLowerCase() === 'netsyncio user') > -1
    );
  }
  checkIfUserIsPresalesEngineer() {
    const claims: any = this.authService.getClaims();

    let roles = [];

    if (typeof claims?.role === 'string') {
      roles = [claims?.role];
    } else {
      roles = claims?.role;
    }

    return (
      roles?.findIndex((item) => item.toLowerCase() === 'pre sales engineers') >
      -1
    );
  }

  checkIfUserIsAccountManger() {
    const claims: any = this.authService.getClaims();

    let roles = [];

    if (typeof claims?.role === 'string') {
      roles = [claims?.role];
    } else {
      roles = claims?.role;
    }

    return (
      roles?.findIndex(
        (item) => item.toLowerCase() === 'netsync account manager'
      ) > -1
    );
  }

  checkIfUserIsInstructor() {
    const claims: any = this.authService.getClaims();
    let roles = [];

    if (typeof claims?.role === 'string') {
      roles = [claims?.role];
    } else {
      roles = claims?.role;
    }

    return roles?.findIndex((item) => item === 'ILE-Instructor') > -1;
  }
}
