import { FooterComponent } from './modules/layout/footer/footer.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './components/landing/landing.component';
import { InterceptService } from './modules/core/intercept.service';
import { LayoutModule } from './modules/layout/layout.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RedirectComponent } from './components/redirect/redirect.component';
import { SharedModule } from './shared/shared.module';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { CacheInterceptor } from './modules/core/cache.interceptor';
import { MatButtonModule } from '@angular/material/button';
import { NewsItemComponent } from './components/news-item/news-item.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CacheMapService } from './modules/core/cache-map.service';
import { Cache } from './modules/core/cache';
import { SplashScreenComponent } from './components/splash-screen/splash-screen.component';
import { AngularTwitterTimelineModule } from 'angular-twitter-timeline';
import { AuthService } from './auth.service';

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    RedirectComponent,
    UnauthorizedComponent,
    FooterComponent,
    NewsItemComponent,
    SplashScreenComponent,
  ],
  imports: [
    CarouselModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    OAuthModule.forRoot(),
    LayoutModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatCardModule,
    MatSidenavModule,
    LoadingBarRouterModule,
    MatIconModule,
    MatTooltipModule,
    SharedModule.forRoot(),
    AngularTwitterTimelineModule,
    MatButtonModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    }),
  ],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
    CacheMapService,
    {
      provide: Cache,
      useClass: CacheMapService,
    },
    AuthService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
