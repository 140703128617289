import { ApiService } from './api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  constructor(private apiService: ApiService) {}
  sendFeedback(data) {
    return this.apiService.post('feedback', data);

  }


}
