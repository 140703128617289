import { RolesService } from './../../shared/service/roles.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { IDPType } from 'src/app/auth.config';

@Component({
  selector: 'nio-redirect',
  template: ` <div class="load" *ngIf="loading">
    <nio-spinner *ngIf="loading"></nio-spinner>
    <p class="loading-text">Loading...</p>
  </div>`,
  styles: [
    `
      .load {
        background: #00000036;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -20px;
        flex-direction: column;
      }
    `,
  ],
})
export class RedirectComponent implements OnInit {
  loading = false;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private rolesService: RolesService,
    private authService: AuthService
  ) {}
  ngOnInit(): void {
    // console.log('Start noOnInit');
    setTimeout(() => {
      this.activeRoute.queryParams.subscribe(
        async (params) => {
          // console.log('params = >>> ', params);
          if (params.error && params.error === 'access_denied') {
            // console.log('access_denied = >>> ', params?.error , params.error_description );
            // this.commonService.errorMessage(params.error_description);
            setTimeout(() => {
              this.authService.logout();
            }, 3000);
            this.router.navigate(['/401'], {
              queryParams: { message: params.error_description || '' },
            });
          } else {
            // console.log('start checking IDP = >>> ', params?.state);
            if (new RegExp(IDPType.azure, 'ig').test(params?.state || 'NAN')) {
              // console.log('start checking IDP is azure = >>> ');
              await this.authService.configureAuth(IDPType.azure);
              this.handleCallback();
            } else if (
              new RegExp(IDPType.auth0, 'ig').test(params?.state || 'NAN')
            ) {
              // console.log('start checking IDP is auth0 = >>> ');
              await this.authService.configureAuth(IDPType.auth0);
              this.handleCallback();
            } else {
              // console.log('Couldnt Identify IDP >>> ');
              this.handleCallback();
            }
          }
        },
        (msg) => {
          // console.log('navigateByUrl >>> ', msg);
          this.router.navigateByUrl(
            `/401?message=${msg || 'You are not authorized.'}`
          );
        }
      );
    }, 2000);
  }

  handleCallback() {
    this.loading = true;
    if (this.authService.isAuthenticated()) {
      // console.log('Is >> Authenticated >>> ');
      const claims: any = this.authService.getClaims();
      if (claims?.defaultPage) {
        // console.log('@defaultPage cond >>> ');
        this.loading = false;
        this.router.navigate(['/classes']);
        // this.router.navigate([claims?.defaultPage]);
      } else {
        this.loading = true;
        // console.log('Is >> Authenticated >>> Else >>> ');
        const isInstructorUser = this.rolesService.checkIfUserIsInstructor();
        // console.log('isCustomerAdmin  >> ', isCustomerAdmin);
        if (isInstructorUser) {
          //  console.log('@Or condition >> ');
          this.loading = false;
          this.router.navigate(['/classes']);
        } else {
          this.loading = true;

          // console.log('@Else Or condition >> ');
          setTimeout(() => {
            this.router.navigate(['/classes']);
            // this.commonService.accessListDataSubject?.subscribe((data) => {
            //   this.loading = false;
            //   // console.log('ACL >> data >> ', data);
            //   const list = data;
            //   if (list && list?.length) {
            //     this.router.navigate(['/' + list[0]]);
            //   }
            // });
          }, 500);
        }
      }
    } else {
      // console.log('Last Else ');
      this.router.navigate(['']);
    }
  }
}
