<div class=" padding-top">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 text-center">
                <p>
                    <img src="../../../../assets/icons/logo.svg">
                </p>
                <p> © {{year}} Netsync Network Solutions | Arapawa {{currentApplicationVersion}}</p>
            </div>

        </div>
    </div>
</div>