<div>
  <img src="/assets/icons/nio-yellow-check.svg" />
  <h1 mat-dialog-title>
    {{ title }}
  </h1>
</div>

<div class="content" mat-dialog-content>
  <p>{{ message }}</p>
  <!-- <mat-form-field fxFlex="100">
    <input matInput placeholder=" Workload name" [formControl]="nameControl" />
    <mat-error> Workload name is <strong>required</strong></mat-error>
  </mat-form-field> -->
</div>

<div class="actions">
  <button mat-stroked-button (click)="onDismiss()">No</button>
  <button color="primary" mat-flat-button (click)="onConfirm()">
    Yes
  </button>
</div>
