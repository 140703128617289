import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { MatSidenavContent } from '@angular/material/sidenav';
import { environment } from '../environments/environment';
import { ToggleThemeService } from './shared/service/toggle-theme.service';
import { CommonService } from './shared/service/common.service';
import { AuthService } from './auth.service';
/* eslint-disable */

@Component({
  selector: 'nio-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  noPadding = false;
  landing = false;
  showScrollButton: boolean;
  showSplash = true;
  currentApplicationVersion = environment.appVersion;

  @Input() clickHamburger: boolean;
  currentYear;
  constructor(
    public router: Router,
    private themeService: ToggleThemeService,
    public commonService: CommonService,
    private authService: AuthService
  ) {
    this.currentYear = new Date().getFullYear();
    //check if user authenticated or not to load primary config
    this.authService.onLoad();

    router.events.subscribe((val: RouterEvent) => {
      if (val instanceof NavigationEnd) {
        if (val.url.toString().startsWith('/?')) {
          this.router.navigateByUrl('/');
        }
        // Check if current page is not landing or profile page
        this.noPadding = val.url === '/' || val.url.includes('profile');
        this.landing = val.url === '/';
      }
      this.commonService.selectedHelpItem = null;
      this.commonService.selectedHelpId = null;
      if (
        this.commonService.clickHamburgerHelp.value == false ||
        !this.commonService.selectedHelpId
      ) {
        this.commonService.clickHamburgerHelp.next(true);
      }
    });
  }

  ngOnInit() {
    this.themeService.setThemeOnStart();
    this.showScrollButton = false;
    setTimeout(() => {
      this.showSplash = false;
    }, 1500);
  }

  public handleScroll(container: MatSidenavContent): void {
    const scrollTop = container.getElementRef().nativeElement.scrollTop;
    this.showScrollButton = scrollTop > 300;
  }

  onActivate(container: MatSidenavContent) {
    document.querySelector('.mat-drawer-content');
    container.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    });
  }

  isAuthorised() {
    return (
      this.router.url !== '/' && this.router.url.indexOf('redirect') === -1
    );
  }
}
