import { RestrictedAccessComponent } from './shared/components/restricted-access/restricted-access.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingComponent } from './components/landing/landing.component';
import { AuthGuard } from './modules/core/guard/auth.guard';
import { AdminGuard } from './modules/core/guard/admin.guard';
import { RedirectComponent } from './components/redirect/redirect.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';

const routes: Routes = [
  { path: '', component: LandingComponent },
  {
    path: 'redirect',
    component: RedirectComponent,
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },

  {
    path: '401',
    component: RestrictedAccessComponent,
  },

  {
    path: 'classes',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/classes/classes.module').then((m) => m.ClassesModule),
  },
  {
    path: 'admin',
    canActivate: [AuthGuard, AdminGuard],
    loadChildren: () =>
      import('./modules/admin/admin.module').then((m) => m.AdminModule),
  },

  { path: '**', redirectTo: '404', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
