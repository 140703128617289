import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppConstant } from 'src/app/shared/app.constant';

@Component({
  selector: 'nio-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent {
  errorMessage = AppConstant.UnAuthorizedErrorMessage;
  constructor(private activeRoute: ActivatedRoute) {
    this.activeRoute.queryParams.subscribe((params) => {
      if (params.message) {
        this.errorMessage = params.message;
      }
    });
  }
}
