<div class="feedback-form">
  <h1>Feedback</h1>
  <small> Please provide your feedback below</small>

  <mat-divider></mat-divider>

  <form [formGroup]="feedbackForm">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="center">
      <div class="smile-buttons">
        <mat-radio-group formControlName="emotion" class="radio-group">
          <mat-radio-button class="radio-button sad" [value]="1">
            <i class="far fa-frown"></i>
          </mat-radio-button>
          <mat-radio-button class="radio-button neutral" [value]="2">
            <i class="far fa-meh"></i>
          </mat-radio-button>
          <mat-radio-button class="radio-button happy" [value]="3">
            <i class="far fa-grin-beam"></i>
          </mat-radio-button>
          <mat-radio-button class="radio-button great" [value]="4">
            <i class="far fa-grin-hearts"></i>
          </mat-radio-button>
        </mat-radio-group>
      </div>

    </div>
    <div class="comments-box">
      <mat-form-field class="full-width">
        <mat-label> How can we make this page better? </mat-label>
        <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5" formControlName="message"></textarea>
        <mat-error *ngIf="getControlErrors('message', 'required')"> Message is
          <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="actions">
      <button mat-stroked-button (click)="onDismiss()">Cancel</button>
      <button type="submit" mat-flat-button [disabled]="feedbackForm.invalid" color="primary"
        (click)="onConfirm(feedbackForm.value)">
        Send
      </button>
    </div>


  </form>
</div>