<header class="overview" id="home-section">
  <div class="overview-overlay">
    <div class="container">
      <div class="row sections">
        <div class="col-8">
          <div
            class="overview-wrap flex flex-column justify-content-center align-items-start"
          >
            <div class="feature-header">
              <h1>
                <span class="title"> Netsync - ILE</span>
                <pre></pre>
                <span>Cloud Based Integrated Learning Environments</span>
              </h1>
            </div>
            <div class="feature-content">
              <p>
                Netsync - ILE is a platform built to allow Professors and
                Instructors the freedom to deploy and manage secure cloud based
                infrastructure specific to their class learning environments
                while providing the technology department administrative tools
                needed to ensure security and financial compliance.
              </p>
            </div>

            <div class="feature-footer read-more">
              <a href="#">read more</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>


<div class="container-fluid landing">
  <div class="center">
    <!-- <section class="labs-features pd-y" id="case-section">
      <div class="container-fluid">
        <div class="flex flex-wrap align-items-stretch">
          <div class="icon-box">
            <div class="feature-header flex">
              <div class="icon">
                <span>
                  <i class="fas fa-regular fa-book-open"></i>
                </span>
              </div>
              <h2 class="feature-title">Case Studies</h2>
            </div>

            <div class="feature-content">
              <p>
                Netsync works with clients throughout the world. Here are a few
                of our recent customers using our solutions.
              </p>
            </div>
            <div class="feature-footer read-more">
              <a href="#"
                >read more<i class="fa fa-solid fa-arrow-right"></i
              ></a>
            </div>
          </div>
          <div class="icon-box">
            <div class="feature-header flex">
              <div class="icon">
                <span>
                  <i class="fa fa-solid fa-globe"></i>
                </span>
              </div>

              <h2 class="feature-title">Funding</h2>
            </div>
            <div class="feature-content">
              <p>
                AWS Consulting Partners have access to specialized funding
                programs that allow customers to offset the costs of their new
                projects
              </p>
            </div>
            <div class="feature-footer read-more">
              <a href="#"
                >read more<i class="fa fa-solid fa-arrow-right"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <section class="about-section pd-y" id="about-section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6 align-content-lg-stretch">
            <div class="heading">
              <h2 class="feature-title">About Netsync - ILE</h2>
              <p>
                Lab environments using Netsync- ILE are enabled by the
                technology department in just a few minutes. When enabled
                Netsync - ILE provide instructors the ability to deploy, manage,
                and add customizations on their own timeline and generally
                independent of the technology organization.
              </p>
              <p>
                When the instructors choose to deploy their lab environments,
                they can keep them running for as long as needed and allowed by
                policy. For example, most of our customers will make their
                environments available throughout the semester, four to six
                months, or even longer if needed.
              </p>
              <p>
                Lab environments can be as simple as a couple of virtual
                machines or as complex as dozens of servers with specific
                security requirements and software installations. In either
                case, Netsync will here to assist your organization to build a
                blueprint of your environments and make them available to
                deploy, scale, and tear down as you see fit.
              </p>
            </div>
          </div>

          <div class="col-12 col-lg-6 flex align-content-center mt-5 mt-lg-0">
            <div class="ezuca-video position-relative">
              <img src="../../../assets/icons/web.jpeg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="latest-news-events pd-y" id="news-section">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="heading flex align-items-center">
              <div class="icon">
                <span>
                  <i class="fas fa-solid fa-calendar"></i>
                </span>
              </div>

              <h2 class="feature-title">Latest News & Events</h2>
            </div>
          </div>

          <div class="col-12 col-lg-6">
            <div class="featured-event-content">
              <figure class="event-thumbnail position-relative m-0">
                <a href="#"
                  ><img src="../../../assets/icons/event-1.jpg" alt=""
                /></a>

                <div class="posted-date position-absolute">
                  <div class="day">23</div>
                  <div class="month">mar</div>
                </div>
              </figure>

              <div class="feature-header flex flex-wrap align-items-center">
                <h2 class="feature-title">
                  <a href="#"
                    >The Complete Financial Analyst Training & Investing
                    Course</a
                  >
                </h2>

                <div class="event-location">
                  <i class="fa fa-map-marker"></i>40 Baria Sreet 133/2 NewYork
                  City, US
                </div>

                <div class="event-duration">
                  <i class="fa fa-calendar"></i>10 Dec - 12 dec
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-6 mt-5 mt-lg-0">
            <div
              class="event-content flex flex-wrap justify-content-between align-content-stretch"
            >
              <figure class="event-thumbnail">
                <a href="#"
                  ><img src="../../../assets/icons/event-2.jpg" alt=""
                /></a>
              </figure>

              <div class="event-content-wrap">
                <div class="feature-header">
                  <div class="posted-date">
                    <i class="fa fa-calendar"></i> 22 Mar 2018
                  </div>

                  <h2 class="feature-title">
                    <a href="#">Personalized online learning experience</a>
                  </h2>

                  <div class="feature-meta flex flex-wrap align-items-center">
                    <div class="post-author">
                      <a href="#">Ms. Lara Croft </a>
                    </div>

                    <div class="post-comments">02 Comments</div>
                  </div>
                </div>

                <div class="feature-content">
                  <p>
                    Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt.
                  </p>
                </div>
              </div>
            </div>

            <div
              class="event-content flex flex-wrap justify-content-between align-content-lg-stretch"
            >
              <figure class="event-thumbnail">
                <a href="#"
                  ><img src="../../../assets/icons/event-3.jpg" alt=""
                /></a>
              </figure>

              <div class="event-content-wrap">
                <div class="feature-header">
                  <div class="posted-date">
                    <i class="fa fa-calendar"></i> 22 Mar 2018
                  </div>

                  <h2 class="feature-title">
                    <a href="#"
                      >Which investment project should my company choose?</a
                    >
                  </h2>

                  <div class="feature-meta flex flex-wrap align-items-center">
                    <div class="post-author">
                      <a href="#">Ms. Lara Croft </a>
                    </div>

                    <div class="post-comments">02 Comments</div>
                  </div>
                </div>

                <div class="feature-content">
                  <p>
                    Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <nio-footer></nio-footer>
  </div>
</div>
