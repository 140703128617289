import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth.service';
import { AppConstant } from 'src/app/shared/app.constant';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const claims = this.authService.getClaims();
    if (this.authService.isAuthenticated()) {
      if (!this.canAccess(claims)) {
        this.router.navigate(['401']);
        return false;
      }
      return true;
    }
    this.router.navigate(['']);
    return false;
  }

  canAccess(claims) {
    if (claims?.role instanceof Array && claims.role?.length > 0) {
      return (
        AppConstant.Roles.findIndex((roleRequired) =>
          claims.role.find((actualRole) => roleRequired === actualRole)
        ) > -1
      );
    }

    return AppConstant.Roles.indexOf(claims.role) > -1;
  }
}
