import { Component } from '@angular/core';

@Component({
  selector: 'nio-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
})
export class SplashScreenComponent {
  constructor() {}
}
