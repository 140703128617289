import { MatToolbarModule } from '@angular/material/toolbar';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { CanAccessDirective } from './directive/can-access.directive';
import { FilterPipe } from './pipes/filter.pipe';
import { StrReplacePipe } from './pipes/str-replace.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { SharedRoutingModule } from './shared-routing.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CardListComponent } from './components/card-list/card-list.component';
import { OneColumnModalComponent } from './components/one-column-modal/one-column-modal.component';
import { RestrictedAccessComponent } from './components/restricted-access/restricted-access.component';
import { SuccessModalComponent } from './components/success-modal/success-modal.component';
import { WarningModalComponent } from './components/warning-modal/warning-modal.component';
import { SortArrPipe } from './pipes/sort-arr.pipe';
import { UploadImagesComponent } from './components/upload-images/upload-images.component';
import { SearchPipe } from './pipes/search.pipe';

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    CanAccessDirective,
    PageNotFoundComponent,
    FeedbackComponent,
    StrReplacePipe,
    TruncatePipe,
    WarningModalComponent,
    SuccessModalComponent,
    FilterPipe,
    PaginatorComponent,
    SpinnerComponent,
    FilterPipe,
    // DateRangePickerComponent,
    SortArrPipe,
    OneColumnModalComponent,
    RestrictedAccessComponent,
    CardListComponent,
    UploadImagesComponent,
    SearchPipe,
  ],
  imports: [
    CommonModule,
    MatSnackBarModule,
    MatDialogModule,
    MatButtonModule,
    SharedRoutingModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatToolbarModule,

    MatProgressSpinnerModule,
    FlexLayoutModule,
    MatDividerModule,
    MatRadioModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatIconModule,
    MatCheckboxModule,
    MatNativeDateModule,
    MatDatepickerModule,
  ],
  exports: [
    CommonModule,
    CanAccessDirective,
    MatSnackBarModule,
    FeedbackComponent,
    PaginatorComponent,
    WarningModalComponent,
    SuccessModalComponent,
    StrReplacePipe,
    TruncatePipe,
    FilterPipe,
    SpinnerComponent,
    SortArrPipe,
    CardListComponent,
    UploadImagesComponent,
    SearchPipe,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
