<mat-toolbar class="custom-navbar">
  <div class="container">
    <button
      mat-icon-button
      class="humburger"
      *ngIf="isUserLoggedIn()"
      (click)="toggle()"
    >
      <img *ngIf="!isCollapsed" src="/assets/icons/left Arrow.svg" />
      <img *ngIf="isCollapsed" src="/assets/icons/Right Arrow.svg" />
    </button>
    <a routerLink="/" class="navbar-brand">
      <img src="/assets/icons/logo.svg" class="logo" />
    </a>

    <nio-navbar *ngIf="!showNavigation"></nio-navbar>
    <a class="link" (click)="onLogin()" routerLink="" *ngIf="!isUserLoggedIn()"
      >Sign in</a
    >

    <div class="username-wrapper" *ngIf="isUserLoggedIn()">
      <button
        class="username"
        mat-icon-button
        [matMenuTriggerFor]="menu"
        disableRipple
      >
        <img src="/assets/icons/profile-icon.svg" />
        <span class="given-name">{{ getClaims()?.given_name }}</span>
        <i class="material-icons"> expand_more </i>
      </button>
      <mat-menu #menu="matMenu">
        <div class="user-info">
          <ng-container *ngIf="getClaims()?.name || isLoading">
            <small> Username:</small>
            <span class="role-name" [class.blur-text]="blurHelper.enableBlur"
              >{{ getClaims()?.name | titlecase }}
              <img
                class="pl-2"
                id="auth0-azure-logo"
                [src]="getImage()"
                alt="logo"
              />
            </span>
          </ng-container>
          <ng-container *ngIf="getRoles() || isLoading">
            <small> Current User Roles : </small>
            <span class="role-name" [class.blur-text]="blurHelper.enableBlur">{{
              getRoles()
            }}</span>
          </ng-container>
        </div>

        <div class="item">
          <mat-slide-toggle
            [checked]="themeService.isDarkThemeSelected"
            (change)="toggleDarkMode($event)"
            disableRipple
          >
            Dark Mode
          </mat-slide-toggle>
        </div>
        <button (click)="onLogoutClick()" id="logout" mat-menu-item>
          Logout
        </button>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>
