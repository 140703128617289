import { HelpSystemSidebarModel } from './../../modules/layout/help-sidebar/help-system-sidebar.model';
import { EventEmitter, Injectable, Output } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from './api.service';
import {
  ConfirmDialogModel,
  ConfirmDialogComponent,
} from '../components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
// import { AccountModel } from 'src/app/modules/operate/account-list/models/account.model';
// import { AuthService } from 'src/app/auth.service';
/* eslint-disable */

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  @Output() clickHamburger: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  @Output() clickHamburgerHelp: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  @Output() selectedAccountChanged: EventEmitter<object> =
    new EventEmitter<object>();
  @Output() newHelpId: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );
  selectedHelpItem: HelpSystemSidebarModel;
  selectedHelpId;
  accountsSubject: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  accessListDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>([]);


  accounts: any = [];
  selectedAccount = {
    Id: null,
    Name: null,
    Description: null,
    CustomerId: null,
    customerProfileImage: null,
  };
  accountManagers = [];
  accessControlList =[];
  // selectedAccountToUpdate: AccountModel;

  constructor(
    private snackBar: MatSnackBar,
    private apiService: ApiService,
    private dialog: MatDialog
  ) {}

  successMessage = (
    message: string,
    durationInSeconds: number = 6,
    verticalPosition: MatSnackBarVerticalPosition = 'top',
    horizontalPosition: MatSnackBarHorizontalPosition = 'right'
  ) => {
    this.snackBarMessage(
      message,
      durationInSeconds,
      verticalPosition,
      horizontalPosition,
      'mat-primary'
    );
  };

  errorMessage = (
    message: string,
    durationInSeconds: number = 6,
    verticalPosition: MatSnackBarVerticalPosition = 'top',
    horizontalPosition: MatSnackBarHorizontalPosition = 'right'
  ) => {
    this.snackBarMessage(
      message,
      durationInSeconds,
      verticalPosition,
      horizontalPosition,
      'mat-warn'
    );
  };

  snackBarMessage = (
    message: string,
    durationInSeconds: number = 2,
    verticalPosition: MatSnackBarVerticalPosition = 'bottom',
    horizontalPosition: MatSnackBarHorizontalPosition = 'center',
    panelClass = 'mat-primary'
  ) => {
    {
      this.snackBar.open(message, '', {
        duration: durationInSeconds * 1000,
        verticalPosition,
        horizontalPosition,
        panelClass: ['mat-toolbar', panelClass],
      });
    }
  };

  confirm(
    message = `Are you sure you want to do this?`,
    title = 'Confirm'
  ): Observable<boolean> {
    const dialogData = new ConfirmDialogModel(title, message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData,
      autoFocus: false,
    });
    return dialogRef.afterClosed();
  }


  getAccounts(discounts?): Observable<any> {
    return this.apiService
      .get<any>('accounts?' + Math.random(),discounts)
      .pipe(
        map((result: any) => {
          if (result.length > 0) {
            const accountManagers = result.at(-2);
            const accessControlList = result.at(-3);
            
            if (Object.keys(accountManagers)?.[0] === 'account_managers') {
              this.accountManagers = accountManagers?.account_managers;
              result.splice(-1, 1);
            }

            if (Object.keys(accessControlList)?.[0] === 'access_control_list') {
              this.accessControlList = accessControlList?.access_control_list            
              
              result.splice(-3, 1);
              
            }

            this.accounts = result;
            
            this.accessListDataSubject.next(this.accessControlList);
            
          }

          if (discounts) {
            return result;
          } else {
            let innerAccounts: Array<any> = Object.values(result);

            try {
              this.accounts = innerAccounts
                ?.sort((a, b) =>
                  a?.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1
                )
                ?.map((account) => {
                  return {
                    Id: account?.id,
                    Name: account?.name,
                    Email: account?.email,
                    Description: account?.description,
                    CustomerId: account?.customer_id,
                    // NetsyncioManaged: account?.netsyncio_managed ? 'Yes' : 'No',
                    AwsServicesDiscount: account?.aws_services_bill_discount,
                    AwsSupportDiscount: account?.aws_support_bill_discount,
                    SupportPlan: account?.support_level,
                    FirstBillableMonth: account?.first_billable_month,
                    LastBillableMonth: account?.last_billable_month,
                    BillableAccount: account?.billable_account,
                    ViewableAccount: account?.billing_viewable,
                    CustomerProfileImage: account?.customer_profile_image,
                    Status:account?.status
                  };
                });
            } catch (err) {
              console.log(err);
            }
          }
          
          this.accountsSubject.next(this.accounts);
          this.accessListDataSubject.next(this.accessControlList);
          

          if (this.selectedAccount.Id == null) {
            this.selectedAccount = this.accounts[1];
            this.selectedAccountChanged.next(this.accounts[1]);
          }

          return this.accounts;
        }),
        catchError(err => {
          console.log('error!');
          
          return of(0)
        })
      );
  }

  updateAccount(payload) {
    return this.apiService.put(`update-account-columns`, payload);
  }

  getHelpSystemItem(id) {
    return this.apiService.get(`help-system?id=` + id);
  }

  getUrlMappingMainURL(shortUrl) {
    return this.apiService.post(`url-mappings/search`, {'shortUrl':shortUrl });
  }

  updateCustomerProfileInfo(customerProfileData, isAzureUserProfileExist = false, isAzureIDP = false) {
    if(isAzureIDP){
      if(isAzureUserProfileExist) {
        return this.apiService.patch(`ad-employee-profile`, customerProfileData);
      } else {
        return this.apiService.post(`ad-employee-profile`, customerProfileData);
      }
    } else {
      return this.apiService.patch(`update-auth0-customer-profile`, customerProfileData);
    }

  }

  showHelpSidebar(id) {
    if (this.clickHamburgerHelp.value) {
      this.clickHamburgerHelp.next(false);
      this.newHelpId.next(id);
    }
  }

  getAzureUserInfo() {
    return this.apiService.get(`ad-employee-profile`).pipe(map((res: any) => {
      return {
        firstName: res?.first_name,
        lastName:  res?.last_name,
        phone:  res?.phone,
        defaultPage:  res?.default_page,
        defaultMode:  res?.default_mode,
        defaultDisplayAccount:  res?.default_display_account,
        }
     }));
  }
}
