import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nio-restricted-access',
  templateUrl: './restricted-access.component.html',
  styleUrls: ['./restricted-access.component.scss']
})
export class RestrictedAccessComponent implements OnInit {
  errorMsg: string = this.route.snapshot.queryParamMap.get('message');

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {

  }

}
