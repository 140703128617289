import { Injectable } from '@angular/core';

declare const window: any;
/* eslint-disable */


@Injectable({
    providedIn: 'root'
})
export class SessionInformationService {

    getBrowserInfo() {
        const userAgent = navigator.userAgent;
        let tem;
        let M = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
            return 'IE ' + (tem[1] || '');
        }
        if (M[1] === 'Chrome') {
            tem = userAgent.match(/\b(OPR|Edge?)\/(\d+)/);
            if (tem != null) { return tem.slice(1).join(' ').replace('OPR', 'Opera').replace('Edg ', 'Edge '); }
        }

        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];

        // eslint-disable-next-line no-cond-assign
        // tslint:disable-next-line: no-conditional-assignment
        if ((tem = userAgent.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }

        return {
            name: M[0],
            version: M[1]
        };
    }

    getOperatingSystem() {
        let OSName = 'Unknown';
        /* eslint-disable eqeqeq */

        // tslint:disable-next-line: triple-equals
        if (window.navigator.userAgent.indexOf('Windows NT 10.0') != -1) { OSName = 'Windows 10'; }
        // tslint:disable-next-line: triple-equals
        else if (window.navigator.userAgent.indexOf('Windows NT 6.2') != -1) { OSName = 'Windows 8'; }
        // tslint:disable-next-line: triple-equals
        else if (window.navigator.userAgent.indexOf('Windows NT 6.1') != -1) { OSName = 'Windows 7'; }
        // tslint:disable-next-line: triple-equals
        else if (window.navigator.userAgent.indexOf('Windows NT 6.0') != -1) { OSName = 'Windows Vista'; }
        // tslint:disable-next-line: triple-equals
        else if (window.navigator.userAgent.indexOf('Windows NT 5.1') != -1) { OSName = 'Windows XP'; }
        // tslint:disable-next-line: triple-equals
        else if (window.navigator.userAgent.indexOf('Windows NT 5.0') != -1) { OSName = 'Windows 2000'; }
        // tslint:disable-next-line: triple-equals
        else if (window.navigator.userAgent.indexOf('Mac') != -1) { OSName = 'Mac/iOS'; }
        // tslint:disable-next-line: triple-equals
        else if (window.navigator.userAgent.indexOf('X11') != -1) { OSName = 'UNIX'; }
        // tslint:disable-next-line: triple-equals
        else if (window.navigator.userAgent.indexOf('Linux') != -1) { OSName = 'Linux'; }
        else { OSName = 'Other'; }

        return OSName;
    }
}
